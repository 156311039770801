// 用于填写充值提现参数的弹出层
import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Link,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
// useRef, useCallback,
import type { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import service from '@/assets/images/service.png'
import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import AtomSelect from '@/components/atom-select/AtomSelect'
// DynamicForms,
import { FormItem } from '@/components/forms/DynamicForms'
import { FormType } from '@/components/forms/types'
import { InfoTooltip } from '@/components/info-tooltip/InfoTooltip'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  type ParamsModalProps,
  RechargeErrorFromType,
  type UseSaveInfoProps,
  type WithdrawArgs,
  type WithdrawArgsData,
} from '@/modules/wallet/interface'
import {
  useDealUsersaveInfoMutation,
  usePixIsEditMutation,
  useRechargeArgsQuery,
  // useFormListQuery,
  useWithdrawArgsQuery,
} from '@/modules/wallet/walletApi'
import {
  COL1_FORM_INPUT_TIPS,
  FORM_INPUT_REG,
  FORM_INPUT_TIPS,
  WITHDRAW_MODAL_BTN_TEXT,
  WITHDRAW_WAYS_TEXT,
} from '@/modules/wallet/walletContansts'
import {
  convertArgsToFormData,
  getHandleType,
  getType,
  getUseSaveInfo,
  getWithdrawalCurrency,
  selectWallet,
  setParamValue,
  setQuestType,
  setWithdrawWays,
  WalletModalTab,
} from '@/modules/wallet/walletSlice'
import { useUserAuth } from '@/utils/hooks'
import { features, projectName } from '@/websites/current/property.json'

import {
  getOpenModal,
  getRechargeAmount,
  getRechargeTyp,
  selectTaskId,
} from '../recharge-activity/rechargeActSlice'
import { BANK_CODE_MAP } from './backListCode'
import TitleWithRechargeWayIcon from './TitleWithRechargeWayIcon'
import { useRecharge } from './useRecharge'
import { useRechargeWays } from './useRechargeWays'
import { useSpecialTips } from './useSpecialTips'
import { useWalletService } from './useWalletService'
import { useWithdraw } from './useWithdraw'
import { handlePIX, handlePIXSubmitInfo } from './utils'
import { CODE_MAP } from './walletContansts'

const { RECAHRGE_INFO_LIST = false } = features
const RECHARGE_ERROR_TIPS = getVariable('RECHARGE_ERROR_TIPS')
const isCOLseriesWallet = isFeatureEnabled('isCOLseriesWallet')

export default function ParamsModal(props: ParamsModalProps) {
  const openWalletService = useWalletService()

  // 获取非充值弹窗调起的充值参数
  const paramsDialogRechargeParams = useAppSelector(
    state => selectWallet(state).paramsDialogRechargeParams,
  )

  // 提现银行账号验证
  const toast = useToast()
  const [is_can_edit, setPixCanEdit] = useState(3)
  const { open, onClose } = props
  const { rechargeSaveInfo } = useRechargeWays()
  const CURRENCY = getCurrencyCode()
  const withdrawCurrency = useAppSelector(getWithdrawalCurrency) || CURRENCY
  const dispatch = useAppDispatch()
  const [dealUsersaveInfo] = useDealUsersaveInfoMutation()
  const { errorText, isUseErrorText } = useSpecialTips()
  // const dynamicRef = useRef<{ isValid: () => boolean }>(null)
  const { t } = useTranslation()
  const tabType = useAppSelector(getType) as WalletModalTab
  const saveInfo: UseSaveInfoProps = useAppSelector(getUseSaveInfo)
  const [user_id, token] = useUserAuth()
  const RECHARGE_BY_POST = getVariable('RECHARGE_BY_POST')
  const WITHDRAW_TYPE = useAppSelector(getHandleType)
  //cons
  const title =
    tabType === WalletModalTab.recharge
      ? t('DEPOSIT_RECHARGE')
      : t('DEPOSIT_WITHDRAWN')

  const amountInRechargeActivityModal = useAppSelector(getRechargeAmount)

  const isRechargeActivityModalOpen = useAppSelector(getOpenModal)
  const taskId = useAppSelector(selectTaskId)
  const rechargeType = useAppSelector(getRechargeTyp)

  const rechargePropMemo = useMemo(() => {
    if (paramsDialogRechargeParams) return paramsDialogRechargeParams
    return isRechargeActivityModalOpen
      ? { rechargeAmount: Number(amountInRechargeActivityModal), taskId }
      : undefined
  }, [
    amountInRechargeActivityModal,
    isRechargeActivityModalOpen,
    paramsDialogRechargeParams,
    taskId,
  ])

  const {
    trigger: recharge,
    result: { isLoading: isRecharging },
  } = useRecharge(rechargePropMemo)

  const {
    trigger: withdraw,
    result: { isLoading: isWithdrawing },
  } = useWithdraw()

  const loading =
    tabType === WalletModalTab.recharge ? isRecharging : isWithdrawing
  const TYPE = RECHARGE_BY_POST ? 'POST' : 'GET'

  const { data: rechargeArgsQueryData } = useRechargeArgsQuery({
    type: TYPE,
    user_id,
    token,
    currency: CURRENCY,
  })
  const { data: withdrawArgsQueryData } = useWithdrawArgsQuery({
    type: TYPE,
    user_id,
    token,
    currency: withdrawCurrency,
  })
  // const { data: formListData } = useFormListQuery({ formType: `withdraw` })

  const walletInfo = useMemo(() => {
    const result: {
      recharge: WithdrawArgsData
      withdraw: Record<string, WithdrawArgsData>
    } = {
      recharge: {
        args: [],
        pay_method: '',
      },
      withdraw: {},
    }

    const rechargeData = rechargeArgsQueryData?.data
    if (rechargeData) {
      result.recharge = rechargeData
    }

    if (withdrawArgsQueryData?.data) {
      result.withdraw = withdrawArgsQueryData?.data.reduce((acc, cur) => {
        const { pay_method } = cur
        acc[pay_method] = cur
        return acc
      }, {} as Record<string, WithdrawArgsData>)
    }
    return result
  }, [rechargeArgsQueryData, withdrawArgsQueryData])

  // const formList = useMemo(() => {
  //   if (!formListData || !formListData.data) return []
  //   return formListData.data.map(item => ({
  //     ...item,
  //     initVal: saveInfo.item[item.key],
  //   }))
  // }, [formListData, saveInfo])
  const [showVerify, setShowVerify] = useState<Array<string>>([])
  const withdrawWays = useAppSelector(state => selectWallet(state).withdrawWays)
  const formParams = useAppSelector(state => ({
    recharge: {
      ...selectWallet(state).rechargeParams,
      ...(isRechargeActivityModalOpen ? { typ: rechargeType } : {}),
    },
    withdraw: selectWallet(state).withdrawParams,
  }))

  const [onPixIsEdit] = usePixIsEditMutation()

  const close = () => {
    onClose(false)
  }

  const isB3QRCodePayment =
    projectName === 'B3' &&
    ['PIXQR', 'PIXQRNEW'].includes(String(formParams?.recharge?.typ))

  const renderForm = (item: WithdrawArgs) => {
    const key = item.name
    const text = t(
      isCOLseriesWallet
        ? COL1_FORM_INPUT_TIPS[key]?.label || FORM_INPUT_TIPS[key]?.label
        : FORM_INPUT_TIPS[key]?.label,
    )
    const placeholder = t(
      isCOLseriesWallet
        ? COL1_FORM_INPUT_TIPS[key]?.tips || FORM_INPUT_TIPS[key]?.tips
        : FORM_INPUT_TIPS[key].tips,
    )
    const labelHelp = handleLabelHelp(item)

    switch (item.type) {
      case 'input':
        return renderInput(key, text, placeholder, labelHelp)
      case 'select':
        return renderSelect(key, text, placeholder, item, labelHelp)
      default:
        return null
    }
  }
  const necessary = useMemo(() => {
    return tabType === WalletModalTab.recharge
  }, [tabType])
  const checkSigleForm = (key: string, value: string) => {
    const keys = [...showVerify]

    const reg = FORM_INPUT_REG[key] ?? FORM_INPUT_REG.common

    const removeBlank =
      tabType === WalletModalTab.recharge &&
      verifyWithAccountName(key) &&
      !!getVariable('RECHARGE_ERROR_TIPS')

    const params = {
      params: formParams[tabType][withdrawWays],
      value,
      necessary: necessary,
      removeBlank, // 是否消除空格统计
      pay_method: withdrawWays,
    }
    const result = reg(params)
    // key === 'account_no' && setGcashPlaceholder((result?.text as string) || '')
    if (!result.verify && keys.indexOf(key) < 0) {
      keys.push(key)
    } else if (result.verify) {
      const index = keys.indexOf(key)
      index >= 0 && keys.splice(index, 1)
    }
    setShowVerify(keys)
    return result
  }
  const renderInput = (
    key: string,
    text: string,
    placeholder: string,
    labelHelp?: null | ReactNode,
  ) => {
    const isInvalid = showVerify.indexOf(key) >= 0

    const removeBlank =
      tabType === WalletModalTab.recharge &&
      verifyWithAccountName(key) &&
      !!getVariable('RECHARGE_ERROR_TIPS')

    // MXN 联动
    const params = formParams[tabType] ?? {}
    text =
      tabType === WalletModalTab.withdraw &&
      withdrawCurrency === 'MXN' &&
      key === 'account_id'
        ? params[withdrawWays]?.document_type || 'RFC'
        : text
    let value = ''
    if (tabType === WalletModalTab.recharge) {
      value = params[key]
    } else {
      const paramsForWithdrawWay = params[withdrawWays]
      if (
        typeof paramsForWithdrawWay === 'object' &&
        paramsForWithdrawWay &&
        key in paramsForWithdrawWay
      ) {
        value = paramsForWithdrawWay[key]
      }
    }
    if (key === 'pix') {
      value = handlePIX(value)
    }

    // 输入框是否可编辑
    const not_can_edit =
      WITHDRAW_TYPE === 'update' && key === 'pix' && is_can_edit !== 3

    let error_text = null

    // console.log(key, isInvalid, !not_can_edit, isUseErrorText(key))
    if (isInvalid) {
      if (!not_can_edit && isUseErrorText(key)) {
        const _ = (params as any)[withdrawWays]
        let BANKCODE = _['bank_code']
          ?.replace(/[^\w^\s^\u4e00-\u9fa5]/gi, '')
          ?.toUpperCase()
        BANKCODE = BANKCODE?.replace(/\s/g, '') || ''

        const code = BANK_CODE_MAP[BANKCODE]?.toString()

        let NUMBER
        if (code) NUMBER = code.substring(code.length - 3)
        error_text = t(
          errorText(key, value, _, removeBlank, withdrawWays) || '',
          {
            BANKCODE,
            NUMBER,
          },
        )
      }

      if (!not_can_edit && !isUseErrorText(key)) {
        error_text = placeholder
      }
    }

    return (
      <Box key={key}>
        <FormItem
          type={FormType.number}
          label={text}
          isInvalid={isInvalid}
          value={value}
          key={key}
          isDisabled={not_can_edit}
          // isDisabled={saveInfo.use}
          showPhoneAreaCode={
            key === 'phone' ||
            (isFeatureEnabled('walletFiledsShowPhoneAreaCode') &&
              key === 'account')
          }
          placeholder={placeholder}
          labelHelp={labelHelp}
          onChange={value => {
            // B系列充值name字段不可连续输入多个空格
            const forbiddenBlank1 =
              verifyWithAccountName(key) &&
              RECHARGE_ERROR_TIPS &&
              tabType === WalletModalTab.recharge
            if (forbiddenBlank1) {
              value = value?.replace(/\s+/g, ' ') || ''
            }

            /** COL1  id_number不可以输入空格 */
            const forbiddenBlank2 =
              isCOLseriesWallet &&
              key === 'id_number' &&
              params?.[withdrawWays]?.id_type === 'CE'
            if (forbiddenBlank2) {
              value = value?.replace(/\s/g, '') || ''
            }

            if (key === 'phone' && tabType === WalletModalTab.withdraw) {
              value = value.replace(/[^\d]+/g, '')
            }

            dispatch(setParamValue({ key, value }))
            checkSigleForm(key, value)
          }}
          error={error_text}
        />
        {not_can_edit && (
          <Box
            sx={{
              width: '100%',
              borderRadius: '8px',
              padding: '8px 12px 8px 32px',
              marginTop: '8px',
              position: 'relative',
            }}
            bgColor={!isInvalid ? 'bg.pixSuccessBg' : 'bg.pixFailBg'}
            color={!isInvalid ? 'text.pixSuccessColor' : 'text.pixFailColor'}
          >
            <InfoOutlineIcon
              width={'14px'}
              height={'14px'}
              color={!isInvalid ? 'text.pixSuccessIcon' : 'text.pixFailIcon'}
              sx={{ position: 'absolute', top: '10px', left: '8px' }}
            />
            <Text
              sx={{
                fontSize: '12px',
                textAlign: 'left',
              }}
            >
              {t(!isInvalid ? 'WITHDRAW_CPF_FIXED' : 'WITHDRAW_CPF_ERROR')}
            </Text>
          </Box>
        )}
      </Box>
    )
  }
  //

  const handleDisabledAccountType = () => {
    if(!isFeatureEnabled('bankCodeCapital')) return false
    if (
      (((formParams[tabType][withdrawWays] as Record<string, string>)?.bank_code) === 'NEQUI') &&
      isFeatureEnabled('bankCodeCapital')
    ) {
      dispatch(setParamValue({ key: 'account_type', value: 'Savings Accounts' }))
      return true
    }
    return false
  }

  console.log('formParams',formParams.withdraw )
  const renderSelect = (
    key: string,
    text: string,
    placeholder: string,
    item: WithdrawArgs,
    labelHelp?: null | ReactNode,
  ) => {
    const options = item?.data?.map(option => ({
      value: typeof option === 'string' ? option : option?.value,
      label:
        typeof option === 'string'
          ? option
          : isFeatureEnabled('bankCodeCapital') && key === 'bank_code'
          ? option?.value.toUpperCase()
          : option?.value,
    }))
    const isInvalid = showVerify.indexOf(key) >= 0

    return (
      <FormItem
        type={FormType.select}
        label={text}
        isInvalid={isInvalid}
        value={
          tabType === WalletModalTab.recharge
            ? (formParams[tabType][key] as string)
            : (formParams[tabType][withdrawWays][key] as string)
        }
        key={key}
        // isDisabled={saveInfo.use}
        placeholder={placeholder}
        onChange={value => {
          dispatch(setParamValue({ key, value: value.trim() }))
          if (key === 'id_type') {
            dispatch(setParamValue({ key: 'id_number', value: '' }))
          }
          if(key === 'bank_code' && value === 'NEQUI' && isFeatureEnabled('bankCodeCapital')) {
            dispatch(setParamValue({ key: 'account_type', value: 'Savings Accounts' }))
          }
          checkSigleForm(key, value)
        }}
        error={isInvalid ? placeholder : null}
        options={options}
        isDisabled={handleDisabledAccountType() && key === 'account_type'}
        labelHelp={labelHelp}
      />
    )
  }

  const handleWalletClick = async () => {
    switch (WITHDRAW_TYPE) {
      case 'add':
        await addInfo()
        break
      case 'update':
        await updateInfo()
        break
      case 'withdraw':
        await addInfo()
        // await walletWithdraw()
        break
      case 'recharge':
        await walletRecharge()
        break
    }
  }
  const getRecahrgeParams = () => {
    const obj = formParams[tabType]
    const _obj = convertArgsToFormData(formatRechargeParams as WithdrawArgs[])
    const result: Record<string, string> = {}
    for (const _key in _obj) {
      result[_key] = obj[_key]
    }
    return result
  }
  const walletRecharge = async () => {
    const flag = calcCheck()
    if (!flag || loading) return
    try {
      dispatch(setQuestType('request'))
      const params = { ...formParams[tabType] }
      if (RECAHRGE_INFO_LIST) {
        params.name = params?.name.replace(/\s+/g, '')
      }
      recharge(
        {
          ...params,
          // ...getRecahrgeParams(),
          pay_method: walletInfo.recharge.pay_method,
        },
        isRechargeActivityModalOpen
          ? RechargeErrorFromType.RecharageAct
          : RechargeErrorFromType.Normal,
      )
    } catch (error) {
      throw new Error(`error`)
    }
  }
  const walletWithdraw = async () => {
    if (loading) return

    if (tabType === WalletModalTab.withdraw) {
      const savedWithdrawData = {
        method: saveInfo.item?.pay_method,
        pay_method: 'id',
        pay_method_id: `${saveInfo.item.id}`,
      }
      const newWithdrawDate = {
        ...formParams[tabType][withdrawWays],
        pay_method: withdrawWays,
      }
      const flag = calcCheck()
      const data = saveInfo.use ? savedWithdrawData : newWithdrawDate
      if (saveInfo.use || flag) {
        dispatch(setQuestType('request'))
        await withdraw(data, close)
        close()
      }
    }
  }
  const calcCheck = (): boolean => {
    // formatRechargeParams formParams[tabType]
    let obj =
      tabType === WalletModalTab.recharge
        ? formParams[tabType] //
        : formParams[tabType][withdrawWays]
    //b系列的充值参数没做分组 需人工职能区分
    if (tabType === WalletModalTab.recharge) {
      obj = { ...getRecahrgeParams() }
    }
    const keys = []
    for (const key in obj) {
      const reg = FORM_INPUT_REG[key] ?? FORM_INPUT_REG.common
      if (
        !reg({
          params: obj,
          value: obj[key],
          necessary: necessary,
          pay_method: withdrawWays,
          removeBlank:
            tabType === WalletModalTab.recharge &&
            verifyWithAccountName(key) &&
            !!getVariable('RECHARGE_ERROR_TIPS'), //  是否消除空格统计
        }).verify
      ) {
        keys.push(key)
      }
    }
    setShowVerify(keys)
    return keys.length === 0
  }
  const updateInfo = async () => {
    if (loading || !Number(saveInfo?.id) || !calcCheck()) return
    const data = {
      data: {
        ...handlePIXSubmitInfo(formParams[tabType][withdrawWays] || {}),
        // currency: withdrawCurrency,
      },
      withdraw_type: saveInfo?.item?.pay_method,
      withdraw_info_id: Number(saveInfo?.id),
    }
    delete data?.data?.id
    delete data?.data?.pay_method
    await dealUsersaveInfo({
      data,
      type: 'edit',
    })
      .unwrap()
      .then(() => {
        close()
      })
      .catch(error => {
        if (error?.status === 1027) {
          toast({
            title: t(error?.message || CODE_MAP[error?.status]),
            status: 'error',
          })
          return
        }
        if (CODE_MAP[error?.status]) {
          toast({ title: t(CODE_MAP[error?.status]), status: 'error' })
        }
      })
  }

  const selectWithdrawWays = () => {
    let options = Object.keys(walletInfo.withdraw).map((item: string) => {
      return {
        value: item,
        label: t(WITHDRAW_WAYS_TEXT[item]).toString(),
      }
    })

    options = options.filter(item => {
      return (
        (item.value !== 'electronic_wallet' &&
          ['M1', 'M5'].includes(projectName)) ||
        (item.value !== 'transfiya' && ['COL1'].includes(projectName)) ||
        !['M1', 'M5', 'COL1'].includes(projectName)
      )
    })

    return (
      <FormControl mt='3' key={1}>
        {/* <FormLabel>{t('WITHDRAW_CHOOSE_METHOD')}</FormLabel> */}
        <AtomSelect
          hasBorder={true}
          size='lg'
          options={options}
          value={options.find(item => item.value === withdrawWays)}
          isDisabled={saveInfo.use}
          onChange={newValue =>
            newValue && dispatch(setWithdrawWays(newValue.value))
          }
        />
      </FormControl>
    )
  }
  //
  const addInfo = async () => {
    if (loading) return
    if (calcCheck()) {
      //
      dealUsersaveInfo({
        data: {
          data: {
            ...handlePIXSubmitInfo(formParams[tabType][withdrawWays] || {}),
          },
          withdraw_type: withdrawWays,
        },
        type: 'add',
      })
        .unwrap()
        .then(result => {
          close()
        })
        .catch(error => {
          if (error?.status === 1027) {
            toast({
              title: t(error?.message || CODE_MAP[error?.status]),
              status: 'error',
            })
            return
          }
          if (CODE_MAP[error?.status]) {
            toast({ title: t(CODE_MAP[error?.status]), status: 'error' })
          }
        })
    }
  }
  const modalTitleElem = (
    <VStack spacing={5}>
      {isB3QRCodePayment && <TitleWithRechargeWayIcon />}
      <HStack justifyContent='center'>
        <Text>{title}</Text>
        {tabType === WalletModalTab.withdraw && (
          <InfoTooltip
            tooltipProps={{
              label: t('PH_PAY_WAY_TIPS').toString(),
              colorScheme: 'white',
            }}
            iconProps={{
              color: 'icon.base',
            }}
          />
        )}
      </HStack>
    </VStack>
  )

  // const onWithdrawParamsFormChange = useCallback(
  //   (key: string, value: string) => {
  //     dispatch(setParamValue({ key, value }))
  //   },
  //   [dispatch],
  // )

  // const withdrawParamsForms = useMemo(
  //   () => formList.filter(item => item.form_id === withdrawWays),
  //   [formList, withdrawWays],
  // )
  // b1的充值参数
  const formatRechargeParams = useMemo(() => {
    if (
      walletInfo?.recharge?.args &&
      tabType === WalletModalTab.recharge &&
      formParams?.recharge?.typ
    ) {
      const _args = isB3QRCodePayment
        ? walletInfo?.recharge?.args.filter(
            item => item.name !== 'phone' && item.name !== 'email',
          )
        : walletInfo?.recharge?.args
      if (formParams?.recharge?.typ.toLocaleUpperCase().includes('PIX')) {
        return _args.filter(item => item?.name !== 'typ')
      }
      return _args
    }
  }, [walletInfo, tabType, formParams, isB3QRCodePayment])
  const bodyRender = () => {
    if (tabType === WalletModalTab.withdraw) {
      return (
        <>
          {Object.keys(walletInfo.withdraw).length > 1 && selectWithdrawWays()}
          {walletInfo?.[tabType]?.[withdrawWays]?.args?.map((item: any) => {
            return renderForm(item)
          })}
          {/* 暂时先按旧版本的逻辑生成表单 暂不使用表单验证组件-平台暂未完全统一成对应的格式*/}
          {/* <DynamicForms
            dForms={withdrawParamsForms}
            ref={dynamicRef}
            onChange={onWithdrawParamsFormChange}
          /> */}
        </>
      )
    } else if (tabType === WalletModalTab.recharge) {
      return (
        <>
          {formatRechargeParams?.map(renderForm)}
          <Text textStyle='text6' mt='5' color='gray.600' mx='1.5'>
            {t('COMFIRM_CPF_DSC')}
          </Text>
        </>
      )
    }
  }

  const serviceClick = () => {
    openWalletService()
  }

  //
  useEffect(() => {
    if (
      open &&
      ((RECAHRGE_INFO_LIST &&
        rechargeSaveInfo?.pix &&
        tabType === WalletModalTab.recharge) ||
        saveInfo.use)
    ) {
      calcCheck()
    } else setShowVerify([])
  }, [open])

  const handlePixCanEdit = async () => {
    await onPixIsEdit({
      withdraw_pix_info_id: Number(saveInfo?.id),
    })
      .unwrap()
      .then(res => {
        const is_can_edit = res?.data?.is_can_edit
        setPixCanEdit(is_can_edit)
      })
  }

  useEffect(() => {
    if (
      open &&
      tabType === WalletModalTab.withdraw &&
      WITHDRAW_TYPE === 'update' &&
      !!RECHARGE_ERROR_TIPS
    ) {
      handlePixCanEdit()
    }
  }, [open, tabType, WITHDRAW_TYPE])
  return (
    <TitledDialog
      title={modalTitleElem}
      isOpen={open}
      onClose={close}
      size='sm'
    >
      {/* m1提现需要选择方式 或者支持多种提现方式*/}
      {bodyRender()}
      <SimpleGrid columns={2} spacing='2.5' w='full' mt='8'>
        <Button
          colorScheme='fill-tert'
          size='lg'
          // isLoading={loading}
          loadingText='Loading'
          onClick={close}
        >
          {t('USER_HEAD_PROTRAIT_CANCEL')}
        </Button>

        <Button
          colorScheme='fill-seco'
          size='lg'
          isLoading={loading}
          loadingText='Loading'
          onClick={handleWalletClick}
        >
          {t(WITHDRAW_MODAL_BTN_TEXT[WITHDRAW_TYPE])}
        </Button>
      </SimpleGrid>
      {/* 只有B系列的提现才展示客服入口 */}
      {tabType === WalletModalTab.withdraw && RECHARGE_ERROR_TIPS && (
        <Box
          mt={'20px'}
          mb={'-10px'}
          display={'inline-flex'}
          alignItems={'center'}
          as='button'
          onClick={serviceClick}
        >
          <LazyImage
            src={service}
            mr='5px'
            w='24px'
            h='24px'
            cursor='pointer'
            alt=''
          />
          <Box color={'#1376FA'} fontSize={'12px'} textDecoration={'underline'}>
            {t('MENU_ONLINE_SERVICE')}
          </Box>
        </Box>
      )}
    </TitledDialog>
  )
}

function handleLabelHelp(item: WithdrawArgs) {
  const TIPS = isCOLseriesWallet ? COL1_FORM_INPUT_TIPS : FORM_INPUT_TIPS
  if (!TIPS[item.name]?.labelHelp) {
    return null
  }
  switch (item.name) {
    case 'bank_account':
      return (
        <HelpLink i18nKey='COMO_OBTENER' url={TIPS[item.name]?.labelHelp} />
      )
    default:
      return null
  }
}

function HelpLink({
  i18nKey,
  url,
  target = '_blank',
}: {
  i18nKey: string
  url: string
  target?: HTMLAttributeAnchorTarget
}) {
  const { t } = useTranslation()
  return (
    <Flex
      alignItems='center'
      fontSize='12px'
      color='link'
      marginBottom='0'
      marginTop='0'
    >
      <Link href={url} target={target} rel='noreferrer'>
        {t(i18nKey)}
      </Link>
    </Flex>
  )
}

function verifyWithAccountName(key: string) {
  /** B series or P series*/
  return key === 'name' || 'account_name'
}
