import { Button, HStack, Icon, Text, useToast, VStack } from '@chakra-ui/react'
import { useGoogleLogin } from '@react-oauth/google'
import { memo } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useTranslation } from 'react-i18next'
import { FaFacebook } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import {
  hideGlobalLoading,
  showGlobalLoading,
} from '@/components/global-loading/global-loading-slice'
import { isWithMessageObject } from '@/services/types'
import { getErrorMessage } from '@/utils/error'
import { useUserAuth } from '@/utils/hooks'
import { getFeatures, isFromApk, isFromVest } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../../../app/store'
import {
  Native3rdPartyLoginChannel,
  parseNative3rdSignResult,
} from '../../../login/GoogleLoginButton'
import {
  AccountType,
  useSetThirdBindMutation,
} from '../../../new-login/loginApi'
import {
  getGoogleUserInfo,
  useBindFacebookAccoutMutation,
  useBindGoogleAccoutMutation,
} from '../../userApi'
import { selectThirdLoginAuthConfig } from '@/modules/new-login/loginAndSignUpAuthConfigSlice'

const GoogleIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.6186 12.2222C21.6186 11.4 21.5518 10.8 21.4073 10.1777H12.0097V13.8888H17.5259C17.4147 14.8111 16.8141 16.2 15.4796 17.1333L15.4609 17.2575L18.4322 19.5573L18.638 19.5778C20.5286 17.8334 21.6186 15.2666 21.6186 12.2222Z'
        fill='#4285F4'
      />
      <path
        d='M12.0094 21.9998C14.7119 21.9998 16.9806 21.1109 18.6377 19.5776L15.4793 17.1331C14.6341 17.722 13.4997 18.1331 12.0094 18.1331C9.36259 18.1331 7.11609 16.3887 6.31528 13.9775L6.1979 13.9875L3.10828 16.3764L3.06787 16.4886C4.71381 19.7553 8.0947 21.9998 12.0094 21.9998Z'
        fill='#34A853'
      />
      <path
        d='M6.31503 13.9778C6.10373 13.3556 5.98144 12.6889 5.98144 12C5.98144 11.3111 6.10373 10.6444 6.30391 10.0222L6.29832 9.8897L3.16998 7.4624L3.06762 7.51104C2.38925 8.86663 2 10.3889 2 12C2 13.6111 2.38925 15.1333 3.06762 16.4889L6.31503 13.9778Z'
        fill='#FBBC05'
      />
      <path
        d='M12.0094 5.86668C13.8889 5.86668 15.1567 6.6778 15.8796 7.35563L18.7044 4.60003C16.9696 2.9889 14.7119 2 12.0094 2C8.0947 2 4.71381 4.24445 3.06787 7.51113L6.30416 10.0223C7.11609 7.61117 9.36258 5.86668 12.0094 5.86668Z'
        fill='#EB4335'
      />
    </svg>
  )
}

const FbIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.6667 22C21.8877 22 22.0996 21.9122 22.2559 21.7559C22.4122 21.5996 22.5 21.3877 22.5 21.1667V2.83333C22.5 2.61232 22.4122 2.40036 22.2559 2.24408C22.0996 2.0878 21.8877 2 21.6667 2H3.33333C3.11232 2 2.90036 2.0878 2.74408 2.24408C2.5878 2.40036 2.5 2.61232 2.5 2.83333V21.1667C2.5 21.3877 2.5878 21.5996 2.74408 21.7559C2.90036 21.9122 3.11232 22 3.33333 22H21.6667Z'
        fill='#39579A'
      />
      <path
        d='M13.1332 22.0002V14.0835H10.4166V11.1668H13.1332V9.01058C13.1332 6.42725 14.7107 5.021 17.0149 5.021C18.1191 5.021 19.0674 5.10308 19.3441 5.13975V7.83975L17.7457 7.84016C16.4924 7.84016 16.2499 8.436 16.2499 9.30975V11.1668H19.1666L18.7499 14.0835H16.2499V22.0002H13.1332Z'
        fill='white'
      />
    </svg>
  )
}

const isNewThirdPartLoginEnable = isFeatureEnabled('newThirdPartLogin')
const hide_third_login = isFeatureEnabled('hide_third_login')
const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

function _TripartitelBind(props: {
  isBindFacebook?: boolean
  isBindGoolge?: boolean
}) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const toast = useToast()
  const [userId, token] = useUserAuth()
  const thirdPartLogin = useAppSelector(selectThirdLoginAuthConfig)
  const { enable_facebook, enable_google } = thirdPartLogin
  /**
   * 新login服务绑定接口
   */
  const [bindThirdAccount] = useSetThirdBindMutation()

  const [bindGoogle] = useBindGoogleAccoutMutation()
  const [bindFacebook] = useBindFacebookAccoutMutation()
  const { isBindFacebook, isBindGoolge } = props

  // 绑定facebkook账号
  const facebookBind = async (response: any) => {
    const { accessToken } = response
    if (!accessToken) {
      return
    }
    try {
      if (loginAndSignUpV3) {
        await bindThirdAccount({
          account_type: AccountType.AT_FACEBOOK,
          account_value: accessToken,
        }).unwrap()
      } else {
        await bindFacebook({
          authorization_code: accessToken,
          token: token,
          user_id: userId,
        }).unwrap()
      }
      toast({ title: t('BINDING_SUCCEEDED'), status: 'success' })
      dispatch(hideGlobalLoading())
    } catch (error: unknown) {
      toast({
        title: isWithMessageObject(error) ? error.message : t('BIND_FAILED'),
        status: 'error',
      })
      dispatch(hideGlobalLoading())
    }
  }

  const getGoogleBindParams = async (accessToken: string) => {
    const result: Parameters<typeof bindGoogle>[0] = {
      token: token,
      user_id: userId,
    }
    if (isNewThirdPartLoginEnable) {
      result.authorization_code = accessToken
      result.redirect_uri = window.location.origin || ''
    } else {
      result.user_info = await getGoogleUserInfo(accessToken)
    }
    return result
  }

  // 授权
  const afterAuthSuccess = async (accessToken: string) => {
    try {
      const params = await getGoogleBindParams(accessToken)
      if (loginAndSignUpV3) {
        await bindThirdAccount({
          account_type: AccountType.AT_GOOGLE,
          account_value: params.authorization_code as string,
        }).unwrap()
      } else {
        await bindGoogle(params).unwrap()
      }
      toast({ title: t('BINDING_SUCCEEDED'), status: 'success' })
    } catch (error: unknown) {
      toast({ title: getErrorMessage(error, 'BIND_FAILED'), status: 'error' })
    } finally {
      dispatch(hideGlobalLoading())
    }
  }

  /**
   * 谷歌H5绑定
   */
  const googleBind = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse: any) => {
      const { code: accessToken } = tokenResponse
      afterAuthSuccess(accessToken)
    },
    onError: () => {
      dispatch(hideGlobalLoading())
    },
    onNonOAuthError: () => {
      dispatch(hideGlobalLoading())
    },
  })

  /**
   * 谷歌原生绑定
   */
  const googleBindNative = () => {
    window.on3rdSignResult = (result: string) => {
      const parsedResult = parseNative3rdSignResult(result)
      if (!parsedResult) return
      if (parsedResult.channel !== Native3rdPartyLoginChannel.Google) return
      const { isSigned, signResult } = parsedResult
      if (isSigned && signResult) {
        afterAuthSuccess(signResult.serverAuthCode)
      } else {
        dispatch(hideGlobalLoading())
      }
    }
    const clientId = getVariable('NEXT_PUBLIC_GOOGLE_CLICENT_ID') ?? ''
    window.AndroidWebView?.do3rdSignIn(
      Native3rdPartyLoginChannel.Google,
      JSON.stringify({ serverClientId: clientId }),
    )
  }

  const handleGoogleBindBtnClick = () => {
    dispatch(showGlobalLoading())
    if (isFromApk()) {
      googleBindNative()
    } else {
      googleBind()
    }
  }

  /**
   * 原生FB绑定
   */
  const on3rdSignResult = (result: string) => {
    const parsedResult = parseNative3rdSignResult(result)
    if (!parsedResult) return
    if (parsedResult.channel !== Native3rdPartyLoginChannel.Facebook) return
    const { isSigned, signResult } = parsedResult
    if (isSigned && signResult) {
      facebookBind({ accessToken: signResult.serverAuthCode })
    } else {
      dispatch(hideGlobalLoading())
    }
  }

  const fbLoginByNative = () => {
    if (!window) return
    window.on3rdSignResult = on3rdSignResult
    window.AndroidWebView?.do3rdSignIn(
      Native3rdPartyLoginChannel.Facebook,
      JSON.stringify({ '': '' }),
    )
  }

  const handleFacebookBindBtnClick = (renderProps: any) => {
    dispatch(showGlobalLoading())
    if (isFromApk()) {
      fbLoginByNative()
    } else {
      renderProps?.onClick()
    }
  }

  if (isFromVest() || hide_third_login) {
    return null
  }
  const style = {
    backgroundColor: '#FFFFFF0D',
    height: '56px',
    textWrap: 'wrap',
    textAlign: 'start',
    color: '#E0E2E5',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '8px',
  }

  return (
    <HStack spacing='4px' w='full'>
      {isBindGoolge && enable_google && (
        <Button
          leftIcon={
            <Icon boxSize='24px'>
              <GoogleIcon />
            </Icon>
          }
          width='full'
          justifyContent='flex-start'
          onClick={handleGoogleBindBtnClick}
          sx={style}
        >
          <Text textStyle='text5' color='personalCenter.navTextColor'>
            {t('PH_GOOGLE_TEXT')}
          </Text>
        </Button>
      )}

      {(() => {
        if (isBindFacebook && enable_facebook) {
          if (!isFromApk()) {
            return (
              <FacebookLogin
                appId={getVariable('NEXT_PUBLIC_FACEBOOK_APP_ID') ?? ''}
                autoLoad={false}
                xfbml={true}
                cookie={true}
                version='2.8'
                fields='name,email,picture'
                render={(renderProps: any) => {
                  return (
                    <Button
                      leftIcon={
                        <Icon color='facebook' boxSize='24px'>
                          <FbIcon />
                        </Icon>
                      }
                      width='full'
                      sx={style}
                      justifyContent='flex-start'
                      onClick={renderProps.onClick}
                    >
                      <Text
                        textStyle='text5'
                        color='personalCenter.navTextColor'
                        cursor='pointer'
                      >
                        {t('PH_FACEBOOK_TEXT')}
                      </Text>
                    </Button>
                  )
                }}
                callback={facebookBind}
              />
            )
          } else {
            return (
              <Button
                leftIcon={
                  <Icon color='facebook' boxSize='24px'>
                    <FbIcon />
                  </Icon>
                }
                width='full'
                sx={style}
                justifyContent='flex-start'
                onClick={handleFacebookBindBtnClick}
              >
                <Text textStyle='text5' cursor='pointer'>
                  {t('PH_FACEBOOK_TEXT')}
                </Text>
              </Button>
            )
          }
        }
      })()}
    </HStack>
  )
}
export const TripartitelBind = memo(_TripartitelBind)
