import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { toString } from 'lodash-es'
import type { FC } from 'react'
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react'
import { useCustomizedStyle, useMobile, useUserAuth } from '@/utils/hooks'
import { getFeatures } from '@/utils/tools'

import { usePageChangeSubscription } from '../../../app-layout/usePageChangeNotification'
import { selectIsOpenActive } from '../../../bind-currency/currencySlice'
import useAccountsInfo from '../../../new-login/hooks/useAccountsInfo'
import { useGetUserVipInfoQuery } from '../../../new-vip/newVipApi'
import { setIsUserVipInfoInvalid } from '../../../new-vip/newVipSlice'
import { TripartitelBind } from './NewTripartitelBind'
import VipInfoCard from '../../../person-center/VipInfoCard'
import PopoverFooter from './PopoverFooter'
import PopoverHeader from './PopoverHeader'
import { useAppSelector } from '@/modules/app/store'
import PopoverMenuNavigations from './PopoverMenuNavigations'
import RefferalCode from './RefferalCode'

const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

interface UserProfilePopoverProps {
  children: React.ReactNode
}

const UserProfilePopoverContext = React.createContext<{
  isOpen: boolean
  onClose: () => void
}>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
})

const Content: FC = () => {
  const { isOpen } = useContext(UserProfilePopoverContext)
  const isOpenActive = useAppSelector(selectIsOpenActive)
  const [userId, token] = useUserAuth()

  /**
   * 获取账号绑定信息
   */
  const { bindInfoValue } = useAccountsInfo()

  const isUserVipInfoInvalid = useAppSelector(setIsUserVipInfoInvalid)

  const { refetch } = useGetUserVipInfoQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )

  useEffect(() => {
    if (isUserVipInfoInvalid && isOpen) {
      refetch()
    }
  }, [refetch, isUserVipInfoInvalid, isOpen])

  const [isBindFacebook, isBindGoogle] = useMemo(() => {
    if (loginAndSignUpV3) {
      return [
        !bindInfoValue?.facebook?.verified,
        !bindInfoValue?.google?.verified,
      ]
    }

    return [true, true]
  }, [bindInfoValue])

  return (
    <>
      <PopoverHeader />
      <RefferalCode />
      <Box p='0'>
        {isOpenActive && <VipInfoCard fromPopover />}
        <PopoverMenuNavigations />
        <TripartitelBind
          isBindFacebook={isBindFacebook}
          isBindGoolge={isBindGoogle}
        />
      </Box>
      <PopoverFooter />
    </>
  )
}

function UserProfilePopover({ children }: UserProfilePopoverProps) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const popoverContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isOpen && popoverContentRef.current) {
      popoverContentRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  const isMobile = useMobile()
  const styles = useCustomizedStyle(
    'UserProfilePopover',
    {
      root: {},
    },
    ['root'],
  )

  return (
    <Popover
      trigger={isMobile ? 'click' : 'hover'}
      onClose={onClose}
      isOpen={isOpen}
    >
      <UserProfilePopoverContext.Provider value={{ isOpen, onClose }}>
        <HidePopoeverOnScroll onClose={onClose} />
        <PopoverTrigger>
          <Box position='relative'>
            {/** trigger box 覆盖头像，防止头像渲染 */}
            <Box
              key={toString(isOpen)}
              onClick={onToggle}
              position='absolute'
              left='0'
              top='0'
              w='full'
              h='full'
              zIndex='9'
            />
            <Box>{children}</Box>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            ref={popoverContentRef}
            width={{ base: '100vw', sm: 'sm' }}
            borderWidth='1px'
            borderStyle='solid'
            borderColor='personalCenter.border'
            boxShadow='0px 2px 12px 0px rgba(0, 0, 0, 0.08)'
            overflow='auto'
            maxHeight='calc(100vh - 150px)'
            borderRadius='10px'
            py='8px'
            px='12px'
            backgroundColor='personalCenter.bg'
            _focusVisible={{ outline: 'none' }}
            sx={styles.root}
            rootProps={{ style: { right: 0 } }}
          >
            <Content />
          </PopoverContent>
        </Portal>
      </UserProfilePopoverContext.Provider>
    </Popover>
  )
}
function HidePopoeverOnScroll(props: { onClose(): void }) {
  const { onClose } = props
  useEffect(() => {
    window?.addEventListener('scroll', () => {
      onClose()
    })
  }, [onClose])

  usePageChangeSubscription(onClose)
  return null
}

export { UserProfilePopoverContext }

export default UserProfilePopover
